<template>
  <van-action-sheet
    v-model="visible"
    :title="title"
    :cancel-text="cancelText"
    @closed="onClosed"
    @cancel="onCancel"
    :closeable="closeable"
  >
    <button
      v-for="(item, index) in actions"
      :key="index"
      class="van-action-sheet__item van-hairline--top"
      :class="{
        active:
          (selectedItem[actionsFilter] != null &&
            selectedItem[actionsFilter] == item[actionsFilter]) ||
          (selectedItem[actionsFilter] == null && item[actionsFilter] == null),
      }"
      @click="onSelect(item, index)"
    >
      <slot v-bind="item">{{ item[showFilter] }}</slot>
    </button>
    <slot name="content"></slot>
  </van-action-sheet>
</template>

<script>
export default {
  name: "common-action-sheet",

  model: {
    prop: "show",
    event: "change",
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    cancelText: {
      type: String,
      default: "",
    },
    closeable: {
      type: Boolean,
      default: false,
    },
    closeOnClickAction: {
      //是否在点击选项后关闭
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: Number,
      default: null,
    },
    actionsFilter: {
      type: String,
      default: "id",
    },
    showFilter: {
      type: String,
      default: "name",
    },
  },

  data() {
    return {
      visible: false,
      active: "",
      selectedItem: {
        id: null,
        name: null,
      },
    };
  },

  watch: {
    show(val) {
      this.visible = val;
      if (val && this.defaultValue) {
        this.selectedItem = this.actions.find(
          (item) => item[this.actionsFilter] == this.defaultValue
        );
      }
    },
  },

  methods: {
    onSelect(item, index) {
      this.selectedItem = item;
      this.active = index;
      if (this.closeOnClickAction) {
        this.visible = !this.visible;
        this.$emit("ok", this.selectedItem);
      }
      this.$emit("change", this.visible);
    },
    onClosed() {
      this.$emit("change", this.visible);
    },
    onCancel() {
      if (this.cancelText == "确定") {
        if (this.actions && this.actions.length > 0) {
          this.selectedItem = this.actions[0];
        }
        this.$emit("ok", this.selectedItem);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.van-action-sheet__item {
  color: #000000;
  position: relative;
  padding: 16px 60px;
  line-height: 24px;
  &.active::before {
    content: "";
    width: 14px;
    height: 10px;
    position: absolute;
    top: calc(50% - 5px);
    right: 20px;
    background: url("~@img/checked.png") center / 100% 100% no-repeat;
  }
}
.van-action-sheet__header {
  font-weight: 600;
}
</style>
