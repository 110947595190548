<template>
  <div class="billDetail">
    <div class="cell bg-white border-r4">
      <CommonTitle title="公司名称" class="van-hairline--bottom" />
      <van-field
        v-model="financeInfo.asName"
        readonly
        @click="showCompany = true"
      >
        <template v-slot:right-icon>
          <img class="right-icon" src="@img/down.png" alt="" />
        </template>
      </van-field>

      <CommonActionSheet
        v-model="showCompany"
        title="公司名称"
        :defaultValue="financeInfo.asId"
        :actions="actions"
        actionsFilter="asId"
        showFilter="asName"
        cancel-text="取消"
        :closeable="true"
        :closeOnClickAction="true"
        @ok="ok"
      >
        <template v-slot="item">
          <div class="companyInfo">
            <div class="name">
              {{ item.asName }}
            </div>
            <div class="extra" :class="item.type.toLowerCase()">
              {{ item.typeDescription }}
            </div>
          </div>
        </template>
      </CommonActionSheet>
    </div>
    <div class="cell bg-white border-r4 mt-12">
      <CommonTitle title="事项" class="van-hairline--bottom" />
      <van-field
        v-model="reason"
        placeholder="请填写您需要备注的内容                                                                                                                                     示例：张三报销21年9月差旅费"
        maxlength="36"
        type="textarea"
        rows="3"
        show-word-limit
      />
    </div>
    <div class="paper-mark">
      <van-field name="checkbox">
        <template #input>
          <van-checkbox v-model="isPaperArchives" shape="square"
            >标记为纸质档案</van-checkbox
          >
        </template>
      </van-field>
      <span class="tip-icon" @click="handleToast"></span>
    </div>
    <div class="btn">
      <van-button type="primary" round @click="handleSubmit">确定</van-button>
    </div>
  </div>
</template>

<script>
import CommonTitle from "@components/CommonTitle";
import CommonActionSheet from "@components/CommonActionSheet.vue";
import { getAccountSets, submitFinance } from "../../../http/api";
export default {
  name: "SubmitFinance",

  components: {
    CommonTitle,
    CommonActionSheet,
  },

  data() {
    return {
      financeInfo: {
        asName: "",
        asId: null,
      },
      isPaperArchives: false,
      reason: "",
      actions: [],
      showCompany: false,
    };
  },

  mounted() {
    this.getCompanyList();
  },

  methods: {
    getCompanyList() {
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
      });
      getAccountSets().then((res) => {
        this.$toast.clear();
        if (!res.statusCode) {
          this.$toast(res.messagge);
          return;
        }
        this.financeInfo = res.data[0];
        this.actions = res.data;
      });
    },
    ok(data) {
      this.showCompany = false;
      this.financeInfo = data;
    },
    handleSubmit() {
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
      });
      let params = {
        bills: JSON.parse(this.$route.query.bills),
        asId: this.financeInfo.asId,
        accountSetType: this.financeInfo.type,
        appAsId: this.financeInfo.appAsId,
        reason: this.reason,
        isPaperArchives: this.isPaperArchives,
      };
      submitFinance(params).then((res) => {
        this.$toast.clear();
        if (!res.statusCode) {
          this.$toast(res.message);
          return;
        }
        this.$toast({
          message: `成功提交${res.data.success}个，失败${res.data.failure}个`,
          duration: 5000,
        });
        this.$router.go(-1);
      });
    },
    handleToast() {
      this.$toast({
        message:
          "标记为纸档后，可在电脑端筛选纸质档案查看对应凭证号，便于在整理纸质档案时在纸档上标注对应的凭证号或快速找到审计所需纸质文件",
        duration: 5000,
        closeOnClickOverlay: true,
        closeOnClick: true,
        mask: true,
        overlayStyle: {
          backgroundColor: 'transparent'
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.billDetail {
  padding: 16px 12px;
  .cell {
    overflow: hidden;
    .van-cell {
      padding: 16px;
      img.right-icon {
        width: 17px;
        height: 16px;
        display: block;
      }
    }
  }
  .btn {
    padding: 0 28px;
    margin-top: 10px;
    .van-button {
      width: 100%;
    }
  }
}
/deep/ .van-action-sheet__item {
  padding: 16px 20px;
}
.companyInfo {
  display: flex;
  .name {
    max-width: 66%;
    display: inline-block;
    position: relative;
    text-align: left;
  }
  .extra {
    display: flex;
    align-items: center;
    height: 20px;
    padding: 0 6px;
    font-size: 12px;
    margin-top: 0px;
    margin-left: 10px;
    border-radius: 2px;
    color: #ffffff;
    &.erp {
      width: 38px;
      color: transparent;
      background: url("~@img/erp.png") center / 100% 100% no-repeat;
    }
    &.acc {
      background: #06c05f;
    }
    &.acc_pro {
      width: 68px;
      color: transparent;
      background: url("~@img/acc-pro.png") center / 100% 100% no-repeat;
    }
    &.aa {
      background: #5059e9;
    }
    &.aa_pro {
      width: 68px;
      color: transparent;
      background: url("~@img/aa-pro.png") center / 100% 100% no-repeat;
    }
  }
}
.paper-mark {
  display: flex;
  align-items: center;
  .van-cell {
    padding-left: 0;
    padding-right: 5px;
    width: 145px;
    background-color: transparent;
    &::after {
      border-bottom: none;
    }
    /deep/ .van-checkbox__label {
      color: #2c3e50;
    }
    /deep/ .van-checkbox__icon {
      margin-right: 12px;
    }
  }
  .tip-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("~@img/question.png") center / 100% 100% no-repeat;
  }
}
</style>
